import styled from '@emotion/styled'
import { X0 } from '@orus.eu/amount'
import { getDiscountRate, type ProductAttributes } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import {
  ButtonLink,
  ContentContainerBackoffice,
  PersistentNotification,
  Text,
  mobileMediaQuery,
  spacing,
  type CustomerData,
} from '@orus.eu/pharaoh'
import { useNavigate, useParams, type NavigateOptions } from '@tanstack/react-router'
import { memo, useMemo, type ReactNode } from 'react'
import { usePermissions } from '../../../../../lib/use-permissions'
import { GlobalLoadingState } from '../../../../molecules/global-loading-state'
import { useCheckoutPagePhase } from '../../../subscription/checkout/checkout-util'
import { CheckoutPageContent } from '../../../subscription/checkout/shared-subscription-checkout-page'
import { useQuoteEditorState } from '../backoffice-quote-editor-v2/quote-editor-v2-state'
import { getDisabledPaymentReason } from './pending-subscription-payment-disabled-reasons'

export const PendingSubscriptionPaymentPage = memo(function PendingSubscriptionPaymentPage(): ReactNode {
  const isPartner = usePermissions().type === 'partner'
  const params = useParams({ strict: false })

  const subscriptionId = params.subscriptionId
  if (!subscriptionId) throw new Error('Missing subscriptionId')

  let navigateBackRoute: NavigateOptions = {
    to: '/bak/v2-pending-subscriptions/$subscriptionId',
    params: { subscriptionId },
  }
  if (isPartner) {
    const { organization } = params
    if (!organization) throw new Error('Missing organization')
    navigateBackRoute = {
      to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
      params: { subscriptionId, organization },
    }
  }

  return (
    <ContentContainerBackoffice>
      <ContractHeader>
        <Text variant="h2">Règlement</Text>
      </ContractHeader>
      <div>
        <ButtonLink variant="text" {...navigateBackRoute}>
          Retour à la souscription
        </ButtonLink>
      </div>
      <PendingSubscriptionPaymentContent />
    </ContentContainerBackoffice>
  )
})

export const PendingSubscriptionPaymentContent = memo(function PendingSubscriptionPaymentContent(): ReactNode {
  const { permissions } = usePermissions()
  const params = useParams({ strict: false })
  const navigate = useNavigate()
  const phase = useCheckoutPagePhase()
  const isPartner = usePermissions().type === 'partner'

  const subscriptionId = params.subscriptionId
  if (!subscriptionId) throw new Error('Missing subscriptionId')

  let navigateBackRoute: NavigateOptions = {
    to: '/bak/v2-pending-subscriptions/$subscriptionId',
    params: { subscriptionId },
  }
  if (isPartner) {
    const { organization } = params
    if (!organization) throw new Error('Missing organization')
    navigateBackRoute = {
      to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
      params: { organization, subscriptionId },
    }
  }

  const { before: state, changes, nonDimensionalDataAfter } = useQuoteEditorState({ subscriptionId })

  const discountRate = getDiscountRate(state?.discount) ?? X0
  const rcdaOneTime = state?.rcdaQuote?.oneTime
  const productsAttributes = useMemo<ProductAttributes[]>(
    () =>
      (state?.offerInformation?.products ?? []).map((product) => ({
        productName: product.name,
        attributes: product.attributes,
      })),
    [state],
  )

  if (!state) return <GlobalLoadingState />
  if (!nonDimensionalDataAfter) return <Text>Souscription incomplète</Text>

  const disabledReason = getDisabledPaymentReason({ changes, state, nonDimensionalDataAfter })

  if (!permissions.includes('contracts.sign'))
    return (
      <PersistentNotification variant="warning">
        Vous n’avez pas l’autorisation d’effectuer des règlements sur cette plateforme
      </PersistentNotification>
    )

  if (disabledReason) return <PersistentNotification variant="warning">{disabledReason}</PersistentNotification>

  if (nonDimensionalDataAfter.contract)
    return <PersistentNotification variant="info">Cette souscription est déjà finalisée</PersistentNotification>

  const customerData: CustomerData = {
    firstName: state.firstName!,
    lastName: state.lastName!,
    email: state.email!,
    address: state.personalAddressStreet!,
    city: state.personalAddressCity!,
    postalCode: state.personalAddressPostCode!,
  }

  return (
    <CheckoutPageContent
      goBackToQuoteStep={() => void navigate(navigateBackRoute)}
      phase={phase}
      subscriptionId={subscriptionId}
      dueDate={state.expectedFirstPaymentDate!}
      quote={state.quote!}
      generalTermsGroups={state.generalTermsGroups!}
      commitment={state.commitment!}
      paymentRecurrence={state.paymentRecurrence!}
      forbiddenMonthlyPayment={state.forbiddenMonthlyPayment!}
      discount={discountRate}
      productsAttributes={productsAttributes}
      startDate={state.startDate!}
      yearlyCommitmentHasDiscount={state.yearlyCommitmentHasDiscount!}
      contractColumnWidth="100%"
      riskCarrierProducts={state.riskCarrierProducts!}
      historyTakeoverPrice={
        rcdaOneTime && rcdaOneTime.final.options.historyTakeover?.active === true
          ? rcdaOneTime.final.options.historyTakeover?.premium.total
          : undefined
      }
      partnerApplicationFee={state?.quote!.partnerApplicationPremium}
      customerData={customerData}
      operatingZone={checkDefinedAndNotNull(state.operatingZone)}
    />
  )
})

export default PendingSubscriptionPaymentPage

const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing[70]};
  ${mobileMediaQuery} {
    margin: 0 ${spacing[70]} 0 ${spacing[70]};
  }
`
